import { Link } from "react-router-dom";
import { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStates,
  fetchStates,
} from "src/plugins/redux/reducers/StatesReducer";
import Papa from "papaparse";

const PricingByStateModal = forwardRef((props: any, ref: any): JSX.Element => {
  const dispatch = useDispatch();
  const selectedStates = props.selectedStates;
  const setSelectedStates = props.setSelectedStates;
  const states = useSelector(selectStates);
  const [priceFile, setPriceFile] = useState();

  useEffect(() => {
    dispatch(fetchStates());
  }, []);

  const handleStateChange = (state, checked) => {
    if (checked) {
      setSelectedStates([
        ...selectedStates,
        {
          state: state,
          prices: [
            {
              price: "",
              distributor: "",
            },
          ],
        },
      ]);
    } else {
      setSelectedStates(
        selectedStates.filter((item) => item.state.id !== state.id)
      );
    }
  };
  const handleResetPrices = () => {
    setSelectedStates([]);
  };


  const handleSubmitStates = () => {
    setSelectedStates([...selectedStates]);
    $("#pricingByStateModal").modal("hide");
    $("#statePriceDistributerModal").modal("show");
  };

  const handleSubmitPrices = () => {
    setSelectedStates([...selectedStates]);
    $("#statePriceDistributerModal").modal("hide");

    const restructuredData = [];

    for (const item of selectedStates) {
      const stateId = item.state.id;

      for (const priceItem of item.prices) {
        // const productId = item.state.id;
        const distributor = priceItem.distributor;
        const price = priceItem.price;

        const restructuredItem = {
          state_id: stateId,
          distributor: distributor,
          price: price,
        };
        console.log("restructuredItem =", restructuredItem);
        console.log("selectedStates", selectedStates);
        restructuredData.push(restructuredItem);
      }
    }
    console.log(`restructuredData = ${restructuredData}`);

    props.setStatePricing(restructuredData);
    props.onClose();
    // props.toggle()
  };

  const handlePriceFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target?.files[0];
    setPriceFile(file.name);

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const csvData = result.data.map((row) => {
            const state = states.find((state) => state.name === row?.State || state.code == row?.State)
            if (state?.id && state.name != '') {
              return {
                id: state.id,
                state: state.name,
                distributor: row?.Distributor,
                price: row["Unit Price"],
              }
            }
          });

          const updatedSelectedStates = csvData.reduce(
            (accumulator, row) => {
              const existingState = accumulator.find(
                (state) => state.state.name === row?.state
              );

              if (existingState) {
                existingState.prices.push({
                  distributor: row?.distributor,
                  price: row?.price,
                });
              } else {
                const newState = {
                  state: {
                    id: states.find((state) => state.name === row?.state)?.id,
                    name: row?.state,
                  },
                  prices: [
                    {
                      distributor: row?.distributor,
                      price: row?.price,
                    },
                  ],
                };
                accumulator.push(newState);
              }

              return accumulator;
            },
            [...selectedStates]
          );

          setSelectedStates(updatedSelectedStates);
        },
      });
    }
  };

  const handleRemovePriceFile = () => {
    setPriceFile(null);
  };

  return (
    <div>
      <div
        className="modal fade"
        id="pricingByStateModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="pricingByStateModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content px-5" style={{ height: "50rem" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="pricingByStateModalLabel">
                Manage Pricing by State
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.onClose();
                }}
              >
                <i aria-hidden="true" className="fa fa-times"></i>
              </button>
            </div>
            <div className="modal-body custom-scroll border-bottom">
              <div className="row mb-3">
                <div className="col-12 col-md-12 pb-5 border-bottom">
                  <label className="w-100">Upload pricing</label>
                  <button
                    type="button"
                    className="btn btn-pill btn-outline-dark"
                    onClick={() => {
                      $("#pricingByStateModal").modal("hide");
                      $("#uploadPricingFileModal").modal("show");
                    }}
                  >
                    <i className="la la-upload"></i>
                    Browse
                  </button>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="fs-3 fw-bold">
                    Select Distributing States
                  </label>
                  <div
                    className="custom-scroll"
                    style={{
                      position: "relative",
                      maxHeight: "280px",
                      overflowY: "scroll",
                    }}
                  >
                    <div className="d-flex align-items-center flex-wrap">
                      {states &&
                        states.map((state, index) => {
                          const isChecked = selectedStates.some(
                            (selectedState) =>
                              selectedState.state.id === state.id
                          );
                          return (
                            <div
                              className="col-4 mb-3"
                              key={`state-checkbox-${index}`}
                            >
                              <label className="checkbox checkbox-dark checkbox-outline">
                                <input
                                  type="checkbox"
                                  name="Checkboxes5"
                                  value={state.id}
                                  checked={isChecked}
                                  onChange={(e) => {
                                    handleStateChange(
                                      { id: state.id, name: state.name },
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span className="mr-3" />
                                {state.name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex flex-column align-items-center justify-content-center">
              <button
                type="button"
                className="btn btn-pill btn-outline-custom-primary font-weight-bold w-100"
                onClick={handleResetPrices}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-pill btn-custom-primary font-weight-bold w-100"
                onClick={handleSubmitStates}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="statePriceDistributerModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="statePriceDistributerModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-scrollable  modal-dialog-centered"
          role="document"
        >
          <div className="modal-content px-5" style={{ height: "50rem" }}>
            <div className="modal-header">
              <h5 className="modal-title" id="statePriceDistributerModalLabel">
                Manage Pricing by State
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  // props.toggle();
                  props.onClose();
                }}
              >
                <i aria-hidden="true" className="ki ki-close"></i>
              </button>
            </div>
            <div className="modal-body custom-scroll">
              <div>
                <div>
                  {selectedStates.map((state, index) => {
                    return (
                      <div
                        className="py-5 border-bottom"
                        key={`state-${index}`}
                      >
                        <strong>{state.state.name}</strong>
                        {state.prices.map((price, index) => {
                          return (
                            <div
                              className="bg-custom-light p-3 rounded-lg mb-3"
                              key={`state-pricing-${index}`}
                            >
                              <div className="form-gorup">
                                <label className="text-muted">
                                  Distriibutor
                                </label>
                                <input
                                  className="form-control bg-custom-light rounded-pill"
                                  type="text"
                                  defaultValue={price.distributor}
                                  onChange={(e) => {
                                    state.prices[index].distributor =
                                      e.target.value;
                                  }}
                                />
                              </div>
                              <div className="form-gorup">
                                <label className="text-muted">Unit Price</label>
                                <input
                                  className="form-control bg-custom-light rounded-pill"
                                  type="number"
                                  step="0.01"
                                  defaultValue={price.price}
                                  onChange={(e) => {
                                    state.prices[index].price = e.target.value;
                                  }}
                                  onKeyDown={(e) => {
                                    // Allow navigation keys (backspace, arrow keys, delete, etc.)
                                    if (
                                      ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"].includes(e.key)
                                    ) {
                                      return;
                                    }
                                    // Allow numeric keys
                                    if (e.key >= "0" && e.key <= "9") {
                                      return;
                                    }
                                    // Allow decimal point
                                    if (e.key === "." && !e.target.value.includes(".")) {
                                      return;
                                    }
                                    // Prevent other inputs
                                    e.preventDefault();
                                  }}
                                  onWheel={(e) => e.preventDefault()}
                                />
                              </div>
                            </div>
                          );
                        })}
                        <a
                          className="text-custom-primary mt-3 cursor-pointer"
                          onClick={() => {
                            state.prices.push({
                              distributor: "",
                              unitPrice: "",
                            });
                            setSelectedStates([...selectedStates]);
                          }}
                        >
                          Add Distributor
                          <i className="la la-plus ml-2"></i>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-pill btn-outline-custom-primary font-weight-bold w-100"
                onClick={handleResetPrices}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-pill btn-dark font-weight-bold w-100"
                onClick={handleSubmitPrices}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="uploadPricingFileModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="uploadPricingFileModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content px-5">
            <div className="modal-header">
              <h5
                className="modal-title fw-bold fs-1"
                id="uploadPricingFileModalLabel"
              >
                Follow these steps to easily upload your data
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  // props.toggle();
                  props.onClose();
                }}
              >
                <i className="fa fa-times fa-xl"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column mb-10 align-items-start">
                <strong className="text-custom-primary mb-4">Step 1</strong>
                <span className="text-muted fs-4">Download our template</span>
                <a
                  className="btn btn-outline-custom-primary btn-pill"
                  href="/assets/pricing_by_state_template.csv"
                  download
                >
                  Download template
                  <i className="la la-download"></i>
                </a>
              </div>
              <div className="d-flex flex-column mb-10">
                <strong>Step 2</strong>
                <span className="text-muted fs-4">
                  Paste your data into the template
                </span>
              </div>
              <div className="d-flex flex-column align-items-start">
                <strong>Step 3</strong>
                <span className="text-muted fs-4">
                  Upload the completed template here
                </span>
                <div>
                  {priceFile ? (
                    <div className="d-flex align-items-center gap-1">
                      <span className="text-primary-blue file-name">
                        {priceFile}
                      </span>
                      <i
                        className="fa fa-times fa-lg cursor-pointer remove-rfp-csv-file"
                        onClick={() => {
                          setPriceFile(null);
                        }}
                      ></i>
                    </div>
                  ) : (
                    <label className="btn btn-outline-custom-primary btn-pill">
                      <input
                        type="file"
                        className="d-none rfp-csv-file"
                        onChange={handlePriceFileChange}
                      />
                      Upload template
                      <i className="la la-upload"></i>
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                disabled={!priceFile}
                type="button"
                className="btn btn-pill btn-dark font-weight-bold w-100"
                onClick={() => {
                  $('#statePriceDistributerModal').modal('show');
                  $("#uploadPricingFileModal").modal("hide");
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default PricingByStateModal;
