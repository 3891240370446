import {
    setHeaderTitle,
    setHeaderActionBtns,
    setHeaderArrowBack,
    setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import VideoPlayer from "src/componenets/VideoPlayer";
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@mui/material';
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector } from "react-redux";
import { getHelp } from "src/plugins/redux/reducers/UsersReducers";
import toast from "react-hot-toast";


export default function TermsOfUse() {

    const dispatch = useDispatch();
    const currentUser = useSelector(currentUserSelector);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const onSubmit = async (data: any) => {
        console.log(data);
        // dispatch(getHelp(data));
        const response = await dispatch(getHelp(data));
        if (response.payload.success) {

            toast.success("Your message has been sent. Please check your email for your confirmation email!");
            setValue("message", "");
        } else {

            toast.error(response.payload);
        }

    };


    useEffect(() => {
        dispatch(setHeaderTitle('Terms Of Use'));
        dispatch(setHeaderActionBtns([]));
        dispatch(setHeaderArrowBack(false));
        dispatch(setShowSearch(false));
    }, [])

    return (
        <div>
            <h1>BevBridge RFP Terms of Use</h1>
            <p>Please review and accept the terms to continue using BevBridge RFP</p>
            <hr>
            </hr>
            <p className="mb-5">By clicking the “Accept” box on the profile page for the BevBridge Digital RFP service (the “Profile Page”), the customer set forth on the Profile Page (“Customer”) accepts and agrees to these Terms of Use (this “Agreement”), as of the date of Customer’s submission of the Profile Page.</p>
            <p className="mb-5">Accordingly, Customer and The BevBridge Group LLC ("BevBridge"), intending to be legally bound, agree as follows:</p>
            <ul style={{
                listStyleType: "decimal",
                fontSize: "13px",
                marginTop: "10px",
                marginBottom: "10px",
            }}>
                <li>
                    <b>Customer.</b> You must be at least 18 years old to use BevBridge. By using our services, you confirm that you meet this age requirement.
                </li>
                <br></br>
                <li>
                    <b> Service Access.</b> BevBridge grants to Customer, during the Term (defined below), the right for Customer to access and use the Service, as a supplier, agency or operator, as set forth in the Profile Page, in the Service.  In addition, BevBridge will provide Customer with technical and user support services in accordance with BevBridge’s standard practices.
                </li>
                <br></br>
                <li>
                    <b>Term.</b> The term of this Agreement will start on Customer’s submission of the Profile Page and will continue until either party terminates this Agreement as set forth below.
                </li>
                <br></br>
                <li>
                    <b>Termination.</b> Customer may elect to terminate this Agreement at any time by providing notice to BevBridge through the Service.  In such event, Customer’s access and use of the Service will continue through the end of the then-current monthly or annual period for which Customer has paid fees (or through the date of termination if Customer does not pay fees).  In addition to any other available rights or remedies, either party elect to terminate this Agreement immediately upon notice to the other describing in reasonable detail a breach of this Agreement by the other party or upon the other party’s failure to continue to function as a going concern or to operate in the ordinary course of business, or upon the bankruptcy, receivership, insolvency, reorganization, dissolution, liquidation or other similar proceedings of the other party.  BevBridge may terminate or suspend any individual Representative (defined below) account or access to the Service in BevBridge’s reasonably discretion.  BevBridge will have no obligation to retain any Customer Data (defined below) for more than thirty (30) days after termination of this Agreement.  Customer will not be entitled, in any circumstances, to a refund of any fees paid.
                </li>
                <br></br>
                <li>
                    <b>Fees.</b> During any trial period, or if Customer does not elect the “premium” or paid Service on the Profile Page, Customer will not be required to pay any fees.  If Customer elects the premium or paid Service on the Profile Page, Customer will pay the fees set forth on the Profile Page, without setoff or deduction, on a monthly or annual basis as elected by Customer on the Profile Page.  Such fees will be due in full prior to the commencement of the applicable period.  Fees set forth on the Profile Page and other amounts payable by Customer under this Agreement are exclusive of taxes and similar assessments, all of which, other than taxes on BevBridge's income, will be the responsibility of Customer and will be added by BevBridge to the fees set forth on the Profile Page.
                </li>
                <br></br>
                <li>
                    <b>Fee Payment.</b> Customer will provide BevBridge’s payment processor with valid major credit card information and will pay by such credit card all fees or any other amounts to which BevBridge may be entitled pursuant to this Agreement.  BevBridge will charge Customer’s credit card in advance of the applicable period for recurring charges and as incurred for any other amounts.  Customer authorizes BevBridge to charge Customer’s credit card as provided above and agrees not to dispute or cancel charges of BevBridge properly made in accordance with this Agreement.
                </li>
                <br></br>
                <li>
                    <b>Customer Responsibilities.</b>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            <b>7.1. Compliance.</b> Customer will operate its business, provide Customer Data (including by obtaining all required consents), and use the Service and BevBridge Content (defined below) in compliance with this Agreement, the documentation supplied by BevBridge relating to the Service (“Documentation”), any policies or requirements issued in writing by BevBridge, and applicable laws and licensing requirements, and without violating or infringing any intellectual property right, right of privacy, or other right of any of Customer Representative or of any third party.  “BevBridge Content” means any information, image, video or other media, other than Customer Data, that is made available to Customer through or in connection with the Service, including any created or provided by BevBridge.  If Customer is a supplier, BevBridge Content includes any of the foregoing made available by agencies or operators (including any included in their RFPs in the Service).  If Customer is an agency or operator, BevBridge Content includes any of the foregoing made available by suppliers (including any in their RFP responses in the Service).
                        </li>
                        <br></br>
                        <li>
                            <b>7.2. Circumvention.</b> Customer will not, directly or indirectly, (i) license, sell, transfer, distribute, publish or otherwise make available or disclose the Service, Documentation, BevBridge Content, BevBridge technology or BevBridge proprietary rights (“BevBridge Assets”), (ii) copy, modify, adapt, decompile, reverse engineer, attempt to duplicate or make derivative, competitive or its own works based upon any of the BevBridge Assets, including any ideas, know-how, features, functions or graphics related to the Service, (iii) frame or mirror any BevBridge information or remove any proprietary notices from the Service or BevBridge Content, (iv) upload or provide any harmful, inaccurate, incomplete, inappropriate or unauthorized information to the Service, (v) interfere with or disrupt the integrity or performance of the Service, (vi) attempt to gain unauthorized access to the Service or any Third Party Service (defined below), (vii) use the Service other than for its own internal business purposes, including for any purpose that is competitive with BevBridge or for the benefit of any third party, or otherwise for any purpose other than the purpose for which the Service is intended, or (viii) assist any third party in performing any of the foregoing.
                        </li>
                        <br></br>
                        <li>
                            <b>7.3. Confidentiality.</b> Without limiting anything in this Section 7, Customer will maintain in strict confidence, and use reasonable care to protect and safeguard (i) the nonpublic terms and conditions of this Agreement or other information relating to the relationship between Customer and BevBridge, and (ii) other than to its Representatives, BevBridge Content or other information, in any form, made available to Customer and reasonably considered by BevBridge as proprietary.  Without limiting anything in this Section 7, Customer will not, directly or indirectly, except as permitted in this Agreement, (i) reproduce, disclose, or make available to any third party any such information, (ii) assist or enable any third party to access or use any such information, or (iii) use or exploit any such information to Customer’s or any other person’s or entity’s enrichment or advantage or to derive any economic gain or for any other purpose whatsoever.  Without limiting the foregoing, Customer will not demonstrate, make available or otherwise describe the feature set of the Service to any person or entity that provides products or services that are similar to or in competition with those of BevBridge.  All materials (including electronic media) provided by BevBridge, and copies thereof, in which any such information may be contained will remain the property of BevBridge.  Upon written request of BevBridge, Customer will promptly deliver to BevBridge all such materials and destroy all materials prepared by Customer in which any such information may be contained.  Notwithstanding the termination or expiration of this Agreement of the delivery or destruction of such materials, Customer will continue to be bound by its obligations under this Section 7.3.
                        </li>
                        <br></br>
                        <li>
                            <b>7.4. Business.</b> Customer will have sole responsibility and liability for all matters and circumstances relating to Customer’s business or, as between Customer and BevBridge, the business of the beverage companies or hospitality company operators, as applicable, with which Customer is employed or for which Customer is an agent or representative, including their use of, or decisions or transactions based on, BevBridge Content.  Customer acknowledges that certain BevBridge Content will be obtained from or provided by persons or entities that use the Service as suppliers, agencies or operators in the Service, and Customer agrees that BevBridge will have no responsibility or liability for the accuracy, completeness, unavailability or any other aspect of such information.
                        </li>
                        <br></br>
                        <li>
                            <b>7.5. Accounts.</b> Customer will establish access requirements for its Representatives, subject to access limits or restrictions of BevBridge, will keep Customer’s and its Representative accounts accurate (including by removing the accounts of Representatives that are no longer authorized), and will be responsible for any and all use of, and activities, data, transactions or administration conducted through or that occur under, Customer’s account or its Representative accounts, whether or not authorized by Customer or the Representative.  BevBridge reserves the right to monitor Customer’s and its Representatives’ use of the Service.
                        </li>
                        <br></br>
                        <li>
                            <b>7.6. Representatives.</b> Customer will be responsible and liable for any acts or omissions of its directors, members, shareholders, partners, managers, officers, employees, agents, representatives, advisors and subcontractors (“Representatives”), including any that result in, or if taken by Customer would constitute, a breach of this Agreement.
                        </li>
                    </ul>
                </li>
                <br></br>
                <li>
                    <b>Data Processing.</b>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            <b>8.1. Customer Data.</b> Customer consents to and authorizes BevBridge’s use of Customer Data in order for BevBridge to provide the Service, including the sharing of Customer Data with persons and entities that access and use Customer Data as a supplier, agency or operator in the Service, as applicable.  Customer also consents to and authorizes the use of Customer Data by such suppliers, agencies and operators, including their downloading (in PDF or other format) of Customer Data, and Customer agrees that BevBridge will have no responsibility or liability in connection with the use, disclosure, privacy or security of such Customer Data (or downloads) by any suppliers, agencies or operators or their Representatives.  “Customer Data” means data that is provided to BevBridge or the Service by Customer or a Customer Representative, including (i) information included in Customer’s submissions of or responses to RFPs in the Service, as applicable, including product information, (ii) other Customer information or information of the beverage companies or hospitality company operators, as applicable, with which Customer is employed or for which Customer is an agent or representative, including pricing, sales data, market data, trademarks, logos and other corporate information, and (iii) information regarding Representatives, including their names, addresses, telephone numbers, email addresses or other personally identifying information.  Customer acknowledges that Customer Data may include information that Customer considers confidential or proprietary.
                        </li>
                        <br></br>
                        <li>
                            <b>8.2. Data Security.</b> BevBridge warrants that (i) Customer Data that is processed by BevBridge will be hosted on systems infrastructure that is consistent with commercially reasonable industry practices for comparable service providers of similar services under similar conditions, (ii) subject to Section 8.1, Customer Data will be managed by BevBridge utilizing technical and administrative safeguards designed to protect against unauthorized access to, or disclosure or use of, Customer Data by BevBridge employees and contractors and against reasonably anticipated third party threats to the security of Customer Data, (iii) BevBridge will promptly report to Customer any unauthorized access to (including any breach of systems security), or disclosure or use of, Customer Data, including a description of any mitigation and corrective action to be taken by BevBridge, and (iv) BevBridge will comply with all laws to which BevBridge is subject regarding the unauthorized access to (including any breach of systems security), or disclosure or use of, Customer Data.  Except for breach by BevBridge of this Section 8.2, BevBridge will have no responsibility or liability with regard to the confidentiality, privacy or security of Customer Data or any unauthorized or unlawful disclosure, misappropriation, destruction, modification, loss, alteration, acquisition, use or access of Customer Data.
                        </li>
                        <br></br>
                        <li>
                            <b>8.3. Third Party Applications.</b> Customer consents to BevBridge’s use of the platforms, systems, products, services and applications of third parties in connection with the provision of the Service (“Third Party Services”) and to the sharing, use and processing of Customer Data with and by such third parties solely for such purpose.  Notwithstanding anything to the contrary, BevBridge will have no responsibility or liability in connection with any of the Third Party Services, including in connection with any unauthorized or unlawful disclosure, misappropriation, destruction, modification, loss, alteration, acquisition, use or access of Customer Data or other data on or processed by any of the Third Party Services.
                        </li>
                        <br></br>
                        <li>
                            <b>8.4. System Data.</b> Notwithstanding anything to the contrary, BevBridge will have the right to monitor, collect and analyze data and other information relating to the provision, use and performance of various aspects of the Service (including information concerning Customer’s use of the Service and Customer Data and data derived therefrom), and BevBridge will be entitled, during and after the term of this Agreement, to use such information and data to improve or enhance the Service, or for other development, diagnostic, or corrective purposes in connection with the Service, or to develop products, analytics or services, and to disclose such information and data in aggregated or other de-identified form in connection with BevBridge’s business.
                        </li>
                    </ul>
                </li>
                <br></br>
                <li>
                    <b>Proprietary Rights.</b>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            <b>9.1. Title to Service.</b> The BevBridge Assets, including BevBridge names, trade secrets, know-how, ideas, designs, forms, methods and other tangible or intangible material or information, and the system data set forth in Section 8.4, are and remain the valuable property of BevBridge.  BevBridge will have exclusive ownership of all materials, technology, ideas, inventions or other work product or information that are developed by BevBridge (including any based on feedback of Customer, any rights to which Customer hereby assigns to BevBridge) in the course of providing the Service.
                        </li>
                        <br></br>
                        <li>
                            <b>9.2. Warranty Against Infringement.</b> BevBridge warrants that the Service (other than BevBridge Content provided by or obtained through agencies, operators or other third parties or any of the Third Party Services) does not infringe any third party's United States patent, copyright or trade secret.
                        </li>
                    </ul>
                </li>
                <br></br>
                <li>
                    <b>Indemnification.</b> BevBridge agrees to indemnify, defend and hold harmless Customer and its Representatives from and against any and all losses, claims, obligations, liabilities, actions, suits, proceedings, demands, judgments, payments, costs and expenses (including court costs, amounts paid in settlement, judgments, and reasonable attorney fees and other expenses) and damages of any kind, nature or description whatsoever (“Damages”) arising out of any breach by BevBridge of Section 8.2 or Section 9.2 or any violation by BevBridge of applicable laws.  Customer agrees to indemnify, defend and hold harmless BevBridge and its Representatives from and against any and all Damages arising out of (i) any breach of this Agreement by Customer, (ii) any matter for which Customer has responsibility or liability under this Agreement or (iii) any act or omission of Customer’s Representatives.
                </li>
                <br></br>
                <li>
                    <b>Limitations of Liability.</b>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            <b>11.1. NO CONSEQUENTIAL DAMAGES.</b> In no event will either party be liable or responsible to the other for any type of incidental, punitive, special, exemplary, reliance, indirect or consequential Damages, even if advised of the possibility of such Damages and regardless of the foreseeability of such Damages.
                        </li>
                        <br></br>
                        <li>
                            <b>11.2. LIABILITY MAXIMUM.</b> In the event BevBridge will be liable to Customer or any of the beverage companies with which Customer is employed or for which Customer is an agent or representative, BevBridge’s liability will not exceed, in the aggregate, the greater of (i) the Service access fees paid by Customer to BevBridge during the twelve (12) months preceding the date on which the liability first arose or (ii) the insurance proceeds collected by BevBridge in respect of the circumstance giving rise to the liability.
                        </li>
                        <br></br>
                        <li>
                            <b>11.3. Warranty Exclusions.</b> Except for the express warranties set forth in this Agreement, BevBridge does not make, and hereby disclaims, to the full extent permitted by law, any and all express or implied (by operation of law or otherwise) or statutory warranties, including warranties of merchantability, fitness for a particular purpose, noninfringement and title and any warranties arising from a course of dealing, usage or trade practice.  Customer hereby waives all Damages based on any of the foregoing.
                        </li>
                    </ul>
                </li>
                <br></br>
                <li>
                    <b>Additional Provisions.</b>
                    <ul style={{ listStyle: "none" }}>
                        <li>
                            <b>12.1. Marketing.</b> BevBridge may publicly refer to Customer and the beverage companies or hospitality company operators with which Customer is employed or for which Customer is an agent or representative, orally and in writing, as a customer of BevBridge and may reference “business case” information for demonstration purposes, to potential customers, investors, regulators and in its marketing efforts.
                        </li>
                        <br></br>
                        <li>
                            <b>12.2. Force Majeure.</b> Neither party will be liable for any failure or delay in its performance under this Agreement (except with respect to any fees due or payable hereunder) due to any cause beyond its reasonable control, including acts of God, sabotage, labor dispute, governmental act, network or connectivity failure, or failure of any third party software, facilities, servers or other products, equipment or services (even if any may be included in the Service).
                        </li>
                        <br></br>
                        <li>
                            <b>12.3. No Publication.</b> The grant of access and use of the Service under this Agreement and the carrying out of the transactions contemplated hereby will not be deemed publication by either party of all or any portion of the Service or Documentation.
                        </li>
                        <br></br>
                        <li>
                            <b>12.4. No Third Party Beneficiaries.</b> There will be no third-party beneficiaries to this Agreement.
                        </li>
                        <br></br>
                        <li>
                            <b>12.5. Severability and Waiver.</b> In the event any provision of this Agreement is held to be contrary to the law, the remaining provisions of this Agreement will remain in full force and effect. The waiver of any breach or default of this Agreement will not constitute a waiver of any subsequent breach or default and will not act to amend or negate the rights of the waiving party.
                        </li>
                        <br></br>
                        <li>
                            <b>12.6. Assignment.</b> This Agreement will bind and inure to the benefit of each party’s successors and permitted assigns.  Neither party may assign this Agreement in whole as part, directly or indirectly, without the express prior written consent of the other party, and any attempted assignment without such consent will be void, provided that, either party may, without the consent of the other party, assign all of its rights and obligations under this Agreement to any person or entity that purchases all or substantially all of its assets or any successor entity to such party by way of merger or other business combination.
                        </li>
                        <br></br>
                        <li>
                            <b>12.7. Notice.</b> Any notice or communication required or permitted to be given hereunder may be delivered by hand, deposited with an overnight courier, sent by email, confirmed facsimile, or mailed by registered or certified mail, return receipt requested, postage prepaid, in each case to the address of the receiving party as listed on the Profile Page or at such other address as may be furnished in writing by either party to the other party.  Such notice will be deemed to have been given as of the date it is delivered, mailed, emailed, faxed or sent, whichever is later.
                        </li>
                        <br></br>
                        <li>
                            <b>12.8. Relationship of Parties.</b> BevBridge and Customer are independent contractors, and this Agreement will not establish any relationship of partnership, joint venture, employment, franchise or agency between BevBridge and Customer.  Neither BevBridge nor Customer will have the power to bind the other or incur obligations on the other’s behalf without the other’s prior written consent.
                        </li>
                        <br></br>
                        <li>
                            <b>12.9. Entire Agreement.</b> This Agreement, including each Profile Page (which is incorporated into and made part of this Agreement), constitutes the complete and exclusive agreement between the parties with respect to the subject matter hereof, and supersedes and replaces any and all prior or contemporaneous discussions, negotiations, understandings and agreements, written and oral, regarding such subject matter.  No text or information set forth on any other purchase order, preprinted form or document (other than a Profile Page) will add to or vary the terms and conditions of this Agreement.  This Agreement may be executed in two or more counterparts, each of which will be deemed an original, but all of which together will constitute one and the same instrument.  For purposes of this Agreement, the term “written” means anything reduced to a tangible form by a party, including a printed or handwritten document, e-mail or other electronic format.  The word “including” (and correlative words, such as “include” and “includes”) will not be construed as a term of limitation but will mean including without limiting the generality of any description preceding such term.
                        </li>
                        <br></br>
                        <li>
                            <b>12.10. Amendment.</b> This Agreement, including the fees set forth in a Profile Page, may be changed by BevBridge from time to time.  Such changes will be effective upon notice to Customer, including by email.  Customer’s continued use of the Service following such notice will constitute Customer’s acceptance and agreement to be legally bound by any such changes, including any adjusted fees.
                        </li>
                        <br></br>
                        <li>
                            <b>12.11. Binding Agreement.</b> Each of the parties to this Agreement, and each person submitting the Profile Page on behalf of such party, represents and warrants that all necessary action has been taken to authorize such party's execution, delivery and performance of this Agreement and that this Agreement is the valid and binding obligation of such party, enforceable in accordance with its terms.
                        </li>
                        <br></br>
                        <li>
                            <b>12.12. Cumulative Rights and Remedies.</b> Except as otherwise expressly provided in the Agreement, the rights and remedies of the parties under this Agreement (including the right to terminate the Agreement and the right to indemnification and the limitations on liability) will be cumulative with and in addition to, not exclusive or in replacement of, any other rights or remedies that may be available under any other agreement between the parties, at law or in equity.  Customer will be liable for all costs and expenses (including reasonable attorney fees) incurred by BevBridge in connection with collecting any fees or enforcing its rights under this Agreement.
                        </li>
                        <br></br>
                        <li>
                            <b>12.13. Survival.</b> The obligations of the parties under this Agreement, which by their nature would continue beyond the termination or expiration of this Agreement, will survive termination or expiration of this Agreement, including Sections 7.2, 7.3, 8.4, 9.1, and 10 - 12.
                        </li>
                        <br></br>
                        <li>
                            <b>12.14. Governing Law.</b> The Agreement will be governed by and construed in accordance with the internal laws of the Commonwealth of Pennsylvania, without regard to its principles of conflicts of laws.  Each of the parties submits to the exclusive, personal jurisdiction of either the Federal District Court for the Eastern District of Pennsylvania, the Court of Common Pleas of Philadelphia County, Pennsylvania or any municipal or local court located in such county for all claims, disputes or controversies involving the parties and relating to the Agreement; provided, however, nothing herein shall prevent a party hereto from asserting a claim for indemnification or any other claim hereunder against the other party hereto in connection with a third party action in the same jurisdiction where a third party action has been brought.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    );

}