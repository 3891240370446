import { forwardRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  deleteFavorite,
  fetchFavorites,
  fetchItem,
  fetchRfpProducts,
  selectCurrentItem,
  selectFavorites,
  selectRfpProducts,
  selectStatus,
} from "src/plugins/redux/reducers/RFPsReducer";
import ProductsFilter from "src/pages/Products/includes/ProductsFilter";
import { currentUserSelector } from "plugins/redux/reducers/AuthReducer";

import {
  setHeaderTitle,
  setHeaderActionBtns,
  setHeaderArrowBack,
  setShowSearch,
} from "src/plugins/redux/reducers/HeaderReducer";
import ProductsFilter from "src/pages/Products/includes/ProductsFilter";
import Papa from "papaparse";
import { useForm } from "react-hook-form";
import ProductsFilterModal from "./productFiltersModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import toast from "react-hot-toast";
import FavoriteIcon from "@mui/icons-material/Favorite";
const ProductsFavouriteList = forwardRef(
  (props: any, ref: any): JSX.Element => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams<{ id: string }>();
    const products = useSelector(selectRfpProducts);
    const status = useSelector(selectStatus);
    const favorites = useSelector(selectFavorites);
    const currentUser = useSelector(currentUserSelector);

    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
    const [filters, setFilters] = useState({});
    const [sortBy, setSortBy] = useState();
    const rfp = useSelector(selectCurrentItem);

    const filteredProducts = products?.filter((product) => {
      return favorites?.some((favorite) => {
        return favorite.product_id === product.id;
      });
    });
    const filteredProductsWithFavoritesId = filteredProducts.map((product) => {
      const favorite = favorites.find((fav) => {
        return fav.product_id === product.id;
      });

      return {
        ...product,
        favoritesId: favorite.id,
      };
    });
    console.log('filteredProductsWithFavoritesId', filteredProductsWithFavoritesId);
    function convertSize(size, price) {
      const ozRegex = /(\d+(\.\d+)?) oz/;
      const mlRegex = /(\d+(\.\d+)?) ml/;
      const literRegex = /(\d+(\.\d+)?) L/;
      const GRegex = /(\d+(\.\d+)?) G/;
      const kegRegex = /Keg - (1\/2|1\/4|1\/6|Euro Keg) Barrel/;

      const ozMatch = size?.match(ozRegex);
      const mlMatch = size?.match(mlRegex);
      const literMatch = size?.match(literRegex);
      const kegMatch = size?.match(kegRegex);
      const GMatch = size?.match(GRegex);

      if (ozMatch) {
        const prices = price / parseFloat(ozMatch[1]);
        return `$${prices.toFixed(2)}`;
      } else if (mlMatch) {
        const mlValue = parseFloat(mlMatch[1]);
        const ozValue = mlValue / 29.5741324921;
        const prices = price / ozValue;
        return `$${prices.toFixed(2)}`;
      } else if (literMatch) {
        const literValue = parseFloat(literMatch[1]);
        const ozValue = literValue * 33.814;
        const prices = price / ozValue;
        return `$${prices.toFixed(2)} `;
      } else if (GMatch) {
        const GValue = parseFloat(GMatch[1]);
        const ozValue = GValue * 128;
        const prices = price / ozValue;
        return `$${prices.toFixed(2)} `;
      } else if (kegMatch) {
        const kegSize = kegMatch[1];
        let kegPrices;
        if (kegSize === "1/2") {
          kegPrices = 1984;
        } else if (kegSize === "1/4") {
          kegPrices = 992;
        } else if (kegSize === "1/6") {
          kegPrices = 661;
        } else if (kegSize === "Euro Keg") {
          kegPrices = 1690;
        }
        if (kegPrices !== undefined) {
          const prices = price / kegPrices;
          return `$${prices.toFixed(2)}`;
        }
      }

      return "-";
    }
    const {
      register,
      handleSubmit,
      watch,
      reset,
      formState: { errors, isValid, dirtyFields },
      getValues,
      control,
      setError,
    } = useForm({});

    useEffect(() => {
      dispatch(
        fetchItem({
          id: id,
        })
      );
      if (currentUser?.role_id == 2) {
        dispatch(
          setHeaderActionBtns([
            {
              link: "/create",
              text: "Add New RFP",
            },
            {
              link: `/rfp/products/favorites-list/${id}`.includes("/favorites-list") ? `/rfp/products/short-list/${id}` : `/rfp/products/favorites-list/${id}`,
              text: `/rfp/products/favorites-list/${id}`.includes("/favorites-list") ? "Favorites Off" : "Favorites",
            },
          ])
        );
      } else if (currentUser?.role_id == 4) {
        dispatch(
          setHeaderActionBtns([
            {
              link: `/rfp/products/favorites-list/${id}`,
              text: "Favorites",
            }, ,

          ])
        );
      } else {
        dispatch(setHeaderActionBtns([]));
      }
      dispatch(setHeaderArrowBack(true));
      dispatch(setShowSearch(false));
      dispatch(fetchRfpProducts({ rfp_id: id }));
      dispatch(fetchFavorites(id));
    }, []);
    useEffect(() => {
      if (rfp.rfp_title) {
        dispatch(setHeaderTitle(`${rfp?.rfp_title} (Favorites)`));
      }
    }, [rfp]);
    const exportToCsv = () => {
      var productsLocal = [];
      filteredProducts?.map((product) => {
        let unitSizes = "";

        if (product.productSizeInfo && product.productSizeInfo.length > 0) {
          unitSizes = product.productSizeInfo
            .filter(size => size?.name != null && size?.name !== '')
            .map(size => size.name)
            .join(", ");
        }

        const product_sell_sheets = product.assets?.find((asset) => asset.assets_type == 'sell_sheets')?.asset_file;
        const image_front = product.assets?.find((asset) => asset.assets_type == 'product_image_front')?.asset_file;
        const image_back = product.assets?.find((asset) => asset.assets_type == 'product_image_back')?.asset_file;
        const label_front = product.assets?.find((asset) => asset.assets_type == 'label_image_front')?.asset_file;
        const label_back = product.assets?.find((asset) => asset.assets_type == 'label_image_back')?.asset_file;
        const recipes = product.recipes?.map((recipe) => { return recipe.asset_file })?.join(',');
        const logos = product.assets?.find((asset) => asset.assets_type == 'logos')?.asset_file;

        productsLocal.push({
          Category: product.category_name ? product.category_name : "",
          "Product Name": product.name ? product.name : "",
          "Product Description": product.description ? product.description : '',
          Supplier: product.supplier_name ? product.supplier_name : "",
          Producer: product.producer_name ? product.producer_name : "",
          Type: product.category_type_name ? product.category_type_name : "",
          "Varietal/Sup-Type": product.category_sub_type_name
            ? product.category_sub_type_name
            : "",
          Pour: product.pour_name ? product.pour_name : "",
          'ABV %': product.abv ? product.abv : "",
          "Prominent Flavors": product.prominentFlavorsInfo ? product.prominentFlavorsInfo?.map((flavor) => { if (flavor?.name) { return flavor.name } }).join(',') : "",
          "Country of Origin": product.country_of_origin_name ? product.country_of_origin_name : "",
          "Region / Appellation": product.region_name ? product.region_name : "",
          "Unit Size": product.unit_size_name ? product.unit_size_name : "",
          "Item Closure": product.item_closure_name
            ? product.item_closure_name
            : "",
          "National Average Unit Price - Case 1": product.national_pricing ? product.national_pricing : "",
          "Price/Ounce": product.price_per_ounce ? product?.price_per_ounce : "",
          "Units per Case": product.units_per_case ? product?.units_per_case : "",
          "Other Available Sizes": unitSizes ? unitSizes : "",
          "Product Sell Sheet": product_sell_sheets ? product_sell_sheets : '',
          "Product Image (front)": image_front ? image_front : '',
          "Product Image (back)": image_back ? image_back : '',
          "Label Image (front)": label_front ? label_front : '',
          "Label Image (back)": label_back ? label_back : '',
          "Recipes": recipes ? recipes : '',
          "Logos": logos ? logos : '',
          "Video Links": product.videos ? product.videos : '',
          "Awards / Accolades": product.awards ? product.awards : '',
          "Notes, if any": product.notes ? product.notes : '',
        });
      });
      var csv = Papa.unparse(productsLocal);

      var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var csvURL = window.URL.createObjectURL(csvData);

      var testLink = document.createElement("a");
      testLink.href = csvURL;
      testLink.setAttribute("test", "test.csv");
      testLink.click();
    };
    const handleFilterProducts = async (data: any) => {
      data.rfp_id = id;
      let sizes = [];
      let flavors = [];
      if (data.sizes && Array.isArray(data.sizes)) {
        sizes = data.sizes.map((size) => size.value);
        data.sizes = sizes.join(",");
      }
      data.sizes = sizes.join(",");

      if (data.flavors && Array.isArray(data.flavors)) {
        flavors = data.flavors.map((flavor) => flavor.value);
        data.flavors = flavors.join(",");
      }

      const response = await dispatch(fetchRfpProducts(data));
      console.log("filter form data", data);
      console.log(response);
      if (response.payload.success || response.payload.error) {
        setShowFiltersModal(false);
        reset();
      }
    };

    //Favorite
    const handleClick = async (Id) => {
      try {
        const response = await dispatch(deleteFavorite(Id));
        if (response.payload.success) {
          dispatch(fetchFavorites(id));
          toast.success("Product is deleted from Favorites");
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <div>
        <form onSubmit={handleSubmit(handleFilterProducts)}>
          <ProductsFilterModal
            showFiltersModal={showFiltersModal}
            setShowFiltersModal={setShowFiltersModal}
            register={register}
            control={control}
            onSubmit={handleSubmit(handleFilterProducts)}
          />
        </form>
        <div className="d-flex align-items-center justify-content-between m-3">
          <button
            type="button"
            className="btn btn-pill btn-outline-custom-primary font-weight-bold"
            onClick={() => exportToCsv()}
          >
            Export CSV
            <i className="la la-upload"></i>
          </button>
          {/* <button
            className="btn btn-outline-custom-primary btn-pill"
            onClick={() => setShowFiltersModal(true)}
          >
            All Filters
            <i className="fa-solid fa-filter"></i>
          </button> */}
        </div>
        <div className="d-flex justify-content-end m-3">
          {/* <button
          type="button"
          className="btn btn-pill btn-dark font-weight-bold"
          onClick={() => exportToCsv()}
        >
          Export Csv
        </button> */}
        </div>
        <div
          style={{ "max-height": "70vh", "overflow-y": "auto" }}
          className="custom-scroll"
        >
          <table className="table rounded-top-pill text-center overflow-hidden">
            <thead className="bg-custom-secondary">
              <tr className="text-white">
                <th>Product Name</th>
                <th>Supplier</th>
                <th>Category</th>
                <th>Type</th>
                <th>Varietal/Sub-Type</th>
                <th>Pour</th>
                <th>Unit Size</th>
                <th>ABV</th>
                <th>Unit Price</th>
                <th>Price/Ounce</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredProductsWithFavoritesId?.map(
                (product: any, index: number) => (
                  <tr>
                    <td>{product.name}</td>
                    <td>
                      {product.supplier_name ? product.supplier_name : "-"}
                    </td>
                    <td>{product.category_name ? product.category_name : "-"}</td>
                    <td>
                      {product.category_type_name
                        ? product.category_type_name
                        : "-"}
                    </td>
                    <td>
                      {product.category_sub_type_name
                        ? product.category_sub_type_name
                        : "-"}
                    </td>
                    <td>
                      {product.pour_name ? product.pour_name : "-"}
                    </td>
                    <td>
                      {product.unit_size_name
                        ? product.unit_size_name
                        : "-"}
                    </td>
                    <td>{product.abv ? `${product.abv} %` : "-"}</td>
                    <td>
                      {product.national_pricing ? `$${product.national_pricing}` : "-"}
                    </td>
                    <td>
                    {product.price_per_ounce ? `$${product.price_per_ounce}` : "-"}
                    </td>
                    <td className="d-flex">
                      <Link
                        className="mr-4"
                        to={`/products/${product.id}`}
                        state={{ product: product }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_4_3167)">
                            <path
                              d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z"
                              fill="#003ACE"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_4_3167">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                      <div
                        onClick={() => handleClick(product.favoritesId)}
                        style={{ cursor: "pointer" }}
                      >
                        <FavoriteIcon sx={{ color: "#003ace" }} />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {status === "error" && (
          <div
            className="d-flex flex-column gap-2 align-items-center justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <strong className="text-center fs-1 text-custom-primary">
              Error occurred while fetching items.
            </strong>
            <button
              className="btn btn-custom-primary btn-pill"
              onClick={() => dispatch(fetchFavorites(id))}
            >
              <i className="fa fa-redo-alt" />
              Retry
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default ProductsFavouriteList;
