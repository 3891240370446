import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

interface PasswordGateProps {
  onAuthorize: () => void;
}

const PasswordGate: React.FC<PasswordGateProps> = ({ onAuthorize }) => {
  const [password, setPassword] = useState("");
  const correctPassword = "yT4d!tm4uTss";
  const COOKIE_NAME = "staging_access";

  useEffect(() => {
    const cookieValue = Cookies.get(COOKIE_NAME);
    if (cookieValue === "true") {
      onAuthorize(); // Automatically authorize if the cookie is present
    }
  }, [onAuthorize]);

  const handleLogin = () => {
    if (password === correctPassword) {
      Cookies.set(COOKIE_NAME, "true", { expires: 7 }); // Set the cookie for 7 days
      onAuthorize(); // Grant access
    } else {
      alert("Incorrect Password");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20vh" }}>
      <h1>Whoops! Looks like you’ve reach our staging site</h1>
      <p>You are probably looking for BevBridge RFP. Please click here to go to <a href="https://rfp.bevbridge.com/">rfp.bevbridge.com</a></p>
      <p>If you think you’re in the right place, then enter the password below</p>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter Password"
        style={{ padding: "10px", marginBottom: "10px", width: "200px" }}
      />
      <button onClick={handleLogin} style={{ padding: "10px 20px" }}>
        Submit
      </button>
    </div>
  );
};

export default PasswordGate;
