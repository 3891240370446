import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "layouts/includes/Header";
import MobileHeader from "layouts/includes/MobileHeader";
import Menu from "layouts/includes/Menu";
import ScrollTop from "layouts/includes/ScrollTop";
import { currentUserSelector } from "src/plugins/redux/reducers/AuthReducer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import VideoPlayer from "src/componenets/VideoPlayer";
import { RemoveFirstLogin } from "src/plugins/redux/reducers/AuthReducer";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function AppLayout() {

  const location = useLocation();
  const currentUser = useSelector(currentUserSelector);
  const isRouteProductProfile = location.pathname.match(/^\/products\/\d+$/) || location.pathname.match(/^\/submission\/\d+$/);
  const navigate = useNavigate();
  const videoShowed = Cookies.get('video_showed');
  const [showVideo, setShowVideo] = useState<boolean>(true);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else {
      if (!videoShowed) {
        setTimeout(() => {
          Cookies.set('video_showed', true);
        }, 5000)
      }
    }
  }, [currentUser])



  const handleCloseVideo = () => {
    setShowVideo(false);
    toast(
      (t) => (
        <span className="d-flex align-items-center justify-content-center gap-2">
          To access this video just go to
          <button
            href="/get-help"
            className="btn btn-custom-primary btn-sm"
            onClick={() => {
              navigate('/get-help');
              toast.dismiss(t.id);
            }}
          >
            Get Help
          </button>
        </span>
      ),
      {
        duration: 1500,
      }
    );

  }


  if (isRouteProductProfile) {
    return (
      <div class="m-0 overflow-hidden">
        <Outlet />
      </div>
    )
  }

  return (
    <div id="kt_body" className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading">
      <MobileHeader />
      {currentUser?.first_login && currentUser.role_id == 3 && !videoShowed && showVideo &&
        <div className="video-container">
          <i className="fa fa-times fa-xl close-video-icon cursor-pointer" onClick={handleCloseVideo}></i>
          <VideoPlayer fluid={false} autoPlay={true} height={'80%'} />
        </div>}
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <Menu />
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <Header />
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content" style={{minHeight: "100vh"}}>
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer" style={{
          width: "100%",
          background: "#ffffff",
          paddingLeft: "15px",
          paddingRight: "15px",
          zIndex: 999,
          position: "relative",
          bottom: 0,
          boxShadow: "-3px 0px 8px 0px black"
        }}>
          <div className="row">
            <div className="col-md-3">
              <img style={{ width: "70px" }} src="/images/BevBridgeRFPLogo.png" />
              <span style={{fontSize: "11px", marginLeft: "12px"}}> © Copyright - The BevBridge Group 2024</span>
            </div>
            <div className="col-md-6" style={{ textAlign: "center", placeContent: "center" }}><Link to="/terms-of-use">Terms Of Use</Link></div>
            <div className="col-md-3" style={{ textAlign: "end", alignContent: "center" }}>
              <a href="https://www.linkedin.com/company/bevbridge/" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 30 30">
                  <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </div >
  );
}
